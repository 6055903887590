<template>
    <div class="page-header header-text">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1>Privacy Policy</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="container my-3">

        <p>Effective Date: 18.01.2025</p>


        <p class="my-4">
            We, Echosilence, take the protection of your personal data very seriously. We treat your personal data
            confidentially and in accordance with the statutory data protection regulations and this data protection
            declaration.
        </p>

        <section>
            <h4>1. Data Collection</h4>
            <p>
                We do not collect, process, or store personal data through this website. Visitors can browse the content
                of the website without providing any personal information.
            </p>
        </section>

        <section>
            <h4>2. Email Communication</h4>
            <p>
                If you choose to contact us via email, we may receive your email address and any other information you
                provide in the email.
                This information will be used solely to respond to your inquiry and will not be shared with third
                parties unless required by law.
            </p>
        </section>

        <section>
            <h4>3. Your Rights</h4>

            <p>
                If you contact us via email and provide personal information, you may have rights under applicable data
                protection laws, such as the right to access, correct, or delete the information you provided. To
                exercise these rights, please contact us at <a
                    href="mailto:contact@echosilence.info">contact@echosilence.info.</a>
            </p>
        </section>

        <section>
            <h4>4. Third-Party Services</h4>
            <p>
                This website does not integrate or share data with any third-party services or companies. No analytics
                or external email marketing services are used.
            </p>
        </section>


        <section>
            <h4>5. Cookies</h4>
            <p>
                we do not use cookies or any 3rd party tracking or analytics services.
            </p>
        </section>

        <section>
            <h4>6. Updates to This Policy</h4>
            <p>
                We may update this privacy policy from time to time. Changes will be posted on this page, and we
                encourage you to review this policy periodically.
            </p>
        </section>

        <section>
            <h4>7. Contact Information</h4>
            <p>If you have any questions about this Privacy Policy or wish to exercise your rights, please contact us:
            </p>

            <a href="mailto:contact@echosilence.info">contact@echosilence.info</a>

        </section>


    </div>

</template>

<style>
section {
    margin-bottom: 20px;
}

ul li {
    font-size: 14px;
}

ul {
    margin-top: 10px;
}
</style>
