<template>
    <template v-if="msgstatus == 1">
<!-- loader -->
    </template>
    <template v-else>
        <div class="page-header header-text">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="contact-information mb-4">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="contact-item">
                            <i class="fa fa-phone"></i>
                            <h4>Phone</h4>
                            <p>Get in touch instantly! Dial our mobile number.</p>
                            <a :href="'tel:' + content.phone">{{ content.phone }}</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="contact-item">
                            <i class="fa fa-envelope"></i>
                            <h4>Email</h4>
                            <p>For any questions or concerns, feel free to email us at</p>
                            <a :href="'mailto:' + content.email">{{ content.email }}</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="contact-item">
                            <i class="fa fa-map-marker"></i>
                            <h4>Location</h4>
                            <p>{{ content.location }}</p>
                            <a @click.prevent="openPopup(gmaps)" href="#">View on Google Maps</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </template>
</template>
<script>

import { mapActions } from 'vuex'

export default {
    data() {
        return {
            loading: false,
            params: {
                name: "",
                email: "",
                subject: "",
                text: ""
            }
        }
    },
    computed: {

        gmaps() {
            return 'https://www.google.com/maps?q=' + this.content.location.replaceAll(" ", "+")
        },
        msgstatus() {
            return this.$store.state.msgstatus
        },
        content() {
            return this.$store.state.content.contact
        }
    },


    methods: {

        ...mapActions(['triggerPopup']),
        openPopup(link) {
            this.triggerPopup(link);
        },

        // sendMessage() {
        //     this.$store.dispatch('sendMessage', this.params)
        // }
    },
    mounted() {
    }
}
</script>
<style></style>