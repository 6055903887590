<template>
  <header class="">
    <nav class="navbar navbar-expand-lg" id="mainNavbar">
      <div class="container">
        <div style="height: 75px;">
          <a class="navbar-brand"><img height="50" src="website/images/logo.png" alt=""></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" :class="$route.name == 'home' ? 'active' : ''">
              <router-link @click="scrollTop" data-toggle="collapse" data-target="#navbarResponsive" class="nav-link" to="home">Home</router-link>
            </li>
            <li class="nav-item" :class="$route.name == 'services' ? 'active' : ''">
              <router-link  @click="scrollTop" data-toggle="collapse" data-target="#navbarResponsive" class="nav-link" to="services-and-products">Services & Products</router-link>
            </li>

            <li class="nav-item" :class="$route.name == 'apps' ? 'active' : ''">
              <router-link  @click="scrollTop" data-toggle="collapse" data-target="#navbarResponsive" class="nav-link" to="applications">Applications</router-link>
            </li>
            <li class="nav-item"  :class="$route.name == 'about' ? 'active' : ''">
              <router-link  @click="scrollTop" data-toggle="collapse" data-target="#navbarResponsive" class="nav-link" to="about">About</router-link>
            </li>
        <li class="nav-item"  :class="$route.name == 'contact' ? 'active' : ''">
              <router-link  @click="scrollTop" data-toggle="collapse" data-target="#navbarResponsive" class="nav-link" to="contact-us">Contact Us</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { mapActions} from 'vuex'
export default {
  props: [],
  data() {
    return {

    }
  },
  computed: {
    contact(){
      return this.$store.state.content.contact
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    ...mapActions(['triggerPopup']),
    openPopup(link) {
      this.triggerPopup(link);
    },
  },
  mounted() { },
  unmounted() { }
}
</script>
<style></style>