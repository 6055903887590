<template>


    <div id="carouselExampleControls" class="carousel slide website-Slider" data-ride="carousel">
      <div class="carousel-inner">
        <div v-for="(item, index) in content.slider.slide" v-bind:key="index" class="carousel-item item"
          :class="index == 0 ? 'active' : ''">
          <div class="img-fill d-none d-lg-block">
            <div class="row align-items-center h-100"
              style="margin-left: 10vw; margin-right: 10vw; text-align: left; height: 100%;">
              <div class="col-12 col-lg-6">
                <div class="text-content">
                  <h4>{{ item.title }}</h4>
                  <!-- <h4>Financial Analysis<br>&amp; Consulting</h4> -->
                  <p>{{ item.text }}</p>
                  <router-link to="contact-us" class="filled-button">Contact us</router-link>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="rounded-img" :style="`background-image: url('/website/images/${item.image}');`">
                </div>
              </div>
            </div>
          </div>
          <div class="img-fill d-block d-lg-none">
            <div class="img-fill">

              <div class="text-content-mob" style="margin-top: 100px; padding: 0 20px; text-align: center;">
                <h4>{{ item.title }}</h4>
                <div class="p">{{ item.text }}</div>
                <router-link to="contact-us" class="filled-button">Contact us</router-link>
              </div>

              <div class="d-flex justify-content-center">
                <div class="rounded-img" :style="`background-image: url('/website/images/${item.image}');`">
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>


    <div class="services mb-4">
      <div class="container">
        <div class="section-heading">
          <h2>Acoustic <em>Experts</em></h2>
          <span>Creating a Quieter and Healthier Future with Advanced Acoustic Technology!</span>
          <div class="service-item">
            <div class="item-content">
              <p class="text-bold" style="font-size: 15px;">
                {{ content.intro }}
              </p>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 my-2" v-for="(item, index) in content.infos.info" v-bind:key="index">
            <div class="service-item" style="height: calc(100% - 80px);">
              <div class="d-flex justify-content-center">
                <div class="img-bubble"><i :class="`fa fa-${item.icon}`"></i></div>
              </div>  
              <div class="item-content text-center" style="height: 100%;">

                <h4>{{ item.title }}</h4>
                <p>{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  computed: {
    content(){
      return this.$store.state.content.home
    }
  },
  methods: {
  },
  mounted() {
  },
}
</script>
<style></style>